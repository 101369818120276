import { isMobileOnly } from 'react-device-detect';

import { createRoot } from 'react-dom/client';
import { nanoid } from 'nanoid';
import { merge } from 'lodash-es';

import { Language } from '@/types/i18n';
import { Layout } from '@/types/client';
import CozeClientWidget from '@/components/coze-client-widget';

// core 入参集合
export interface CozeChatConfig {
  bot_id: string;
  conversation_id: string;
  user: string;
  sdk_verify_token?: string;
}

interface ComponentProps {
  layout?: Layout;
  lang?: Language;
  title?: string;
  icon?: string;
  zIndex?: number;
  uploadable?: boolean;
}

export interface CozeChatOptions {
  config: {
    /**
     * 对外保持统一风格，兼容旧版本
     */
    bot_id?: string;
    sdk_verify_token?: string;
    botId: string;
  };
  componentProps?: ComponentProps;
  el?: HTMLElement;
}

export interface IframeParams {
  // 通信标识
  // iframe回传父页面 的事件前缀 => 用于父级页面的 多实例 区分 message 来源
  chatClientId: string;
  chatConfig: CozeChatConfig;
  componentProps?: Omit<ComponentProps, 'zIndex'>;
}

export class WebChatClient {
  static clients: WebChatClient[] = [];
  private root: ReturnType<typeof createRoot>;
  private readonly defaultRoot: HTMLDivElement;
  readonly chatClientId = nanoid();
  readonly iframeParamsSearch: string;
  readonly options: CozeChatOptions;

  public constructor(options: CozeChatOptions) {
    const { el, componentProps, config } = options;

    /**
     * 使用 merge 防止形如 key: undefined 覆盖默认参数
     * TODO: 入参需要隔离备份
     */
    options.componentProps = merge(
      {
        layout: isMobileOnly ? Layout.MOBILE : Layout.PC,
        lang: Language.EN,
        uploadable: true,
      },
      componentProps,
    );

    this.options = options;

    this.iframeParamsSearch = encodeURIComponent(
      JSON.stringify({
        chatClientId: this.chatClientId,
        chatConfig: {
          bot_id: config?.botId ?? config?.bot_id,
          user: nanoid(),
          conversation_id: nanoid(),
        },
        componentProps: options.componentProps,
      }),
    );

    if (el) {
      this.root = createRoot(el);
      this.root.render(<CozeClientWidget client={this} position="static" />);
    } else {
      this.defaultRoot = document.createElement('div');
      document.body.appendChild(this.defaultRoot);
      this.root = createRoot(this.defaultRoot);
      this.root.render(<CozeClientWidget client={this} />);
    }

    WebChatClient.clients.push(this);
  }

  destroy() {
    this.root.unmount();
    if (this.defaultRoot) {
      this.defaultRoot.remove();
    }

    WebChatClient.clients = WebChatClient.clients.filter(c => c !== this);
  }
}
