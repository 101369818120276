import { createMinimalBrowserClient } from '@slardar/web/client';
import { jsErrorPlugin } from '@slardar/integrations/dist/jsError';
import { customPlugin } from '@slardar/integrations/dist/custom';
import { getSlardarEnv } from '@flow-studio/chat-core';
import { Logger, SlardarReportClient, LogLevel } from '@flow-infra/logger';

import { ChatSdkError, SDKErrorCode } from '@/util/error';
import { type Integration, type SlardarMiniClient } from '@/types/slardar';
import { ChatSDKSlardarEnvEnum } from '@/log/slardar';
import { type ReportEvent } from '@/log/event';

class SdkReporter {
  private slardarInstance: SlardarMiniClient;
  private readonly reportInstance: SlardarReportClient;
  private readonly logger: Logger;

  constructor() {
    this.initSlardar();
    this.reportInstance = new SlardarReportClient(this.slardarInstance);
    this.logger = new Logger({
      ctx: {
        namespace: ChatSDKSlardarEnvEnum.ChatAppSdkClient,
        level: LogLevel.INFO,
      },
      clients: [this.reportInstance],
    });
  }

  private initSlardar() {
    this.slardarInstance = createMinimalBrowserClient();
    this.slardarInstance.init({
      bid: 'bot_studio_sdk',
      /**
       * 与core上报到同一处 bid_env，打通数据
       */
      env: getSlardarEnv({
        env: 'thirdPart',
        deployVersion: 'release',
      }),
      integrations: [
        jsErrorPlugin() as unknown as Integration,
        customPlugin() as unknown as Integration,
      ],
    });
    this.slardarInstance.start();
  }

  log(message: string, payload: unknown) {
    this.logger.info({
      message,
      meta: payload as Record<string, unknown>,
    });
  }

  report(event: ReportEvent, payload: Record<string, unknown>) {
    this.logger.persist.info({
      eventName: event,
      meta: payload,
    });
  }

  error(
    error: Error | unknown,
    options?: {
      code: SDKErrorCode;
      payload?: Record<string, unknown>;
    },
  ) {
    const { code = SDKErrorCode.Base, payload } = options ?? {};

    this.logger.persist.error({
      error: ChatSdkError.wrap(
        error instanceof Error
          ? error
          : ChatSdkError.create(SDKErrorCode.NotError),
        code,
      ),
      eventName: SDKErrorCode[code] || SDKErrorCode[SDKErrorCode.Unknow],
      meta: payload,
    });
  }

  public getSlardarClient(): ReturnType<typeof createMinimalBrowserClient> {
    return this.slardarInstance as unknown as ReturnType<
      typeof createMinimalBrowserClient
    >;
  }

  public getLogger() {
    return this.logger;
  }
}

export const clientLogger = new SdkReporter();

clientLogger.log(
  `client env => REGION:${REGION} IS_DEV_MODE:${IS_DEV_MODE} IS_BOE:${IS_BOE}`,
  {},
);
